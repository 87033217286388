<div class="terms-wrapper">
  <div class="terms-top">
    <div class="terms-center lf-bg-2">
      <div class="terms-title">How can we help?</div>

    </div>
  </div>

  <div class="terms-second">
    <div class="terms-center">
      <div class="terms-intro">Welcome to Morerooms Customer Support</div>
      <div class="term-para">
        <p>
          As part of our ongoing commitment to offer our customers the best possible services, we have created a simple enquiry form below. You can select the most appropriate option as per your enquiry & one of our experts will respond within 24 hours.
        </p>
      </div>
    </div>
  </div>
  <div class="pls-wait-overlay" *ngIf="PleaseWait">
    <div class="min-pls">
      <div class="pls-cnnt">
        <div> <i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></div>
        Please Wait...
      </div>
    </div>
  </div>

  <div class="terms-accordion">
    <div class="terms-center">
      <div class="acc-mainn2">
        <div class="acc-list2" *ngIf="Amendment.length>0">
          <div class="all-atitle2 " [ngClass]="{'active':TabValue == '1'}" (click)="TabValue = TabValue && TabValue == '1' ? '' : '1' "> <img src="assets/images/amandment-icon-cs.svg" alt="Amendment"> Amendment    </div>
          <div class="acc-cont2 " [ngClass]="{'show':TabValue == '1'}">
            <form [formGroup]="AmendmentForm" (ngSubmit)="AmendmentFormonSubmit()" #AmendmentForms="ngForm">
              <div class="cs-form-wrap">
                <div class="cs-para">
                  <p>For all amendments please fill the below section</p>
                  <p>Ensure to provide More rooms Reference No (example: {{refText}}) to complete the request.</p>
                </div>
                <div class="cs-formwrap">
                  <div class="form-group">
                    <select class="cs-select" formControlName="Type" (change)="AmendmentFormTypeChange()">
                      <option *ngFor="let cat of Amendment" value="{{cat.categoryId}}">{{cat.subcategory}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Name of lead guest" formControlName="Leadguest" class="cs-input" maxlength="50"
                           [ngClass]="{ 'is-invalid': AmendmentForms.submitted && AmendmentForm.controls.Leadguest.errors }">
                    <div *ngIf="AmendmentForms.submitted && AmendmentForm.controls.Leadguest.errors">
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.Leadguest.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter lead guest name</div>
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.Leadguest.errors.pattern">  <img src="assets/images/error-valid-img.svg" /> Please enter valid lead guest name</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Booking Reference Number" formControlName="ReferenceNumber"  maxlength="15" class="cs-input text-transform-uppercase"
                           [ngClass]="{ 'is-invalid': AmendmentForms.submitted && AmendmentForm.controls.ReferenceNumber.errors }">
                    <div *ngIf="AmendmentForms.submitted && AmendmentForm.controls.ReferenceNumber.errors">
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.ReferenceNumber.errors.required">  <img src="assets/images/error-valid-img.svg" /> Please enter reference number</div>
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.ReferenceNumber.errors.pattern">  <img src="assets/images/error-valid-img.svg" /> Please enter valid reference number</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Email ID" formControlName="EmailID" class="cs-input" maxlength="50"
                           [ngClass]="{ 'is-invalid': AmendmentForms.submitted && AmendmentForm.controls.EmailID.errors }">
                    <div *ngIf="AmendmentForms.submitted && AmendmentForm.controls.EmailID.errors">
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.EmailID.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter email Id</div>
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.EmailID.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid email Id</div>
                    </div>
                  </div>


                  <div class="form-group">
                    <ngx-intl-tel-input class="cs-input" [preferredCountries]="PreferredCountriesName" [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="false" [customPlaceholder]="'Contact Number'" [searchCountryFlag]="true" autocomplete="disabled" [selectFirstCountry]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectedCountryISO]= "CountryISOName" minLength="9" maxLength="11" [phoneValidation]="true"
                                        [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.National" name="ContactNumber"
                                        formControlName="ContactNumber"
                                        [ngClass]="{'is-invalid':AmendmentForms.submitted && AmendmentForm.controls.ContactNumber.errors}">

                    </ngx-intl-tel-input>
                    <div *ngIf="AmendmentForms.submitted && AmendmentForm.controls.ContactNumber.errors">
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.ContactNumber.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter contact number </div>
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.ContactNumber.errors.incorrect">  <img src="assets/images/error-valid-img.svg" /> Please enter valid contact number</div>
                    </div>

                  </div>
                  <div class="form-group" (click)="checkin.show();">
                    <input type="text" placeholder="Check-in date" (focus)="checkin.show();" formControlName="Checkindate" maxlength="50" class="cs-input"
                           [ngClass]="{ 'is-invalid': AmendmentForms.submitted && AmendmentForm.controls.Checkindate.errors }" [minDate]="minDate" [maxDate]="maxDate"
                           #checkin="bsDatepicker" [outsideClick]="true" bsDatepicker readonly container
                           [bsConfig]="{dateInputFormat: CompanySettings.DateFormat,containerClass: 'theme-red',adaptivePosition: true,isAnimated: true,showWeekNumbers: false}">
                    <div *ngIf="AmendmentForms.submitted && AmendmentForm.controls.Checkindate.errors">
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.Checkindate.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter check-in date </div>
                    </div>
                  </div>
                  <div class="form-group" [ngStyle]="{'display': AmendmentForm.get('Type')?.value == '4' || AmendmentForm.get('Type')?.value == '2' || AmendmentForm.get('Type')?.value == '3' || AmendmentForm.get('Type')?.value == '212' || AmendmentForm.get('Type')?.value == '213' ? '':'none'}">
                    <input type="text" placeholder="Full name as per passport" formControlName="NameChange" class="cs-input" maxlength="50"
                           [ngClass]="{ 'is-invalid': AmendmentForms.submitted && AmendmentForm.controls.NameChange.errors }">
                    <div *ngIf="AmendmentForms.submitted && AmendmentForm.controls.NameChange.errors">
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.NameChange.errors.required">  <img src="assets/images/error-valid-img.svg" /> Please enter full name as per passport</div>
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.NameChange.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid full name as per passport</div>
                    </div>
                  </div>
                  <div class="form-group" (click)="checkinGuestDob.show();" [ngStyle]="{'display': AmendmentForm.get('Type')?.value== '4' || AmendmentForm.get('Type')?.value== '213' ? '':'none'}">
                    <input type="text" placeholder="Guest DOB" (focus)="checkinGuestDob.show();" formControlName="GuestDob" maxlength="50" class="cs-input"
                           [ngClass]="{ 'is-invalid': AmendmentForms.submitted && AmendmentForm.controls.GuestDob.errors }" [maxDate]="maxDate"
                           #checkinGuestDob="bsDatepicker" [outsideClick]="true" bsDatepicker readonly container
                           [bsConfig]="{dateInputFormat: CompanySettings.DateFormat,containerClass: 'theme-red',adaptivePosition: true,isAnimated: true,showWeekNumbers: false}">
                    <div *ngIf="AmendmentForms.submitted && AmendmentForm.controls.GuestDob.errors">
                      <div class="val-red" *ngIf="AmendmentForms.submitted && AmendmentForm.controls.GuestDob.errors"> <img src="assets/images/error-valid-img.svg" /> Please enter guest DOB </div>
                    </div>
                  </div>
                  <div class="text-area-bx">
                    <textarea placeholder="Additional Comments" formControlName="AdditionalComments" maxlength="1000" class="cs-text-area"></textarea>
                  </div>
                  <div class="cs-submit-main">
                    <div class="sub-right">
                      <input type="submit" value="Submit" class="sub-cs">
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="acc-list2" *ngIf="SpecialRequest.length>0">
          <div class="all-atitle2" [ngClass]="{'active':TabValue == '2'}" (click)="TabValue = TabValue && TabValue == '2' ? '' : '2' "> <img src="assets/images/spacial-service.svg" alt="Special Request"> Special Request </div>
          <div class="acc-cont2" [ngClass]="{'show':TabValue == '2'}">
            <form [formGroup]="SpecialRequestForm" (ngSubmit)="SpecialRequestFormonSubmit()" #SpecialRequestForms="ngForm">
              <div class="cs-form-wrap">
                <div class="cs-para">
                  <p>For all amendments please fill the below section</p>
                  <p>Ensure to provide More rooms Reference No (example: {{refText}}) to complete the request.</p>
                </div>
                <div class="cs-formwrap">
                  <div class="form-group">
                    <select class="cs-select" formControlName="Type" (change)="SpecialRequestFormTypeChange()">
                      <option *ngFor="let cat of SpecialRequest" value="{{cat.categoryId}}">{{cat.subcategory}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Name of lead guest" class="cs-input" formControlName="Leadguest" maxlength="50"
                           [ngClass]="{ 'is-invalid': SpecialRequestForms.submitted && SpecialRequestForm.controls.Leadguest.errors }">
                    <div *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.Leadguest.errors">
                      <div class="val-red" *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.Leadguest.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter lead guest name</div>
                      <div class="val-red" *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.Leadguest.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid lead guest name</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Booking Reference Number" formControlName="ReferenceNumber"  maxlength="15" class="cs-input text-transform-uppercase"
                           [ngClass]="{ 'is-invalid': SpecialRequestForms.submitted && SpecialRequestForm.controls.ReferenceNumber.errors }">
                    <div *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.ReferenceNumber.errors">
                      <div class="val-red" *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.ReferenceNumber.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter reference number</div>
                      <div class="val-red" *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.ReferenceNumber.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid reference number</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Email ID" class="cs-input" formControlName="EmailID" maxlength="50"
                           [ngClass]="{ 'is-invalid': SpecialRequestForms.submitted && SpecialRequestForm.controls.EmailID.errors }">
                    <div *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.EmailID.errors">
                      <div class="val-red" *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.EmailID.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter email Id </div>
                      <div class="val-red" *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.EmailID.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid email Id </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <ngx-intl-tel-input class="cs-input" [preferredCountries]="PreferredCountriesName" [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true" [customPlaceholder]="'Contact Number'" [searchCountryFlag]="true" autocomplete="disabled" [selectFirstCountry]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectedCountryISO]="CountryISOName" maxLength="11" [phoneValidation]="true"
                                        [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.National" name="ContactNumber"
                                        formControlName="ContactNumber"
                                        [ngClass]="{'is-invalid':SpecialRequestForms.submitted && SpecialRequestForm.controls.ContactNumber.errors}">

                    </ngx-intl-tel-input>
                    <div *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.ContactNumber.errors">
                      <div class="val-red" *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.ContactNumber.errors.required"> <img src="assets/images/error-valid-img.svg" />  Please enter contact number </div>
                      <div class="val-red" *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.ContactNumber.errors.incorrect"> <img src="assets/images/error-valid-img.svg" />   Please enter valid contact number </div>

                    </div>

                  </div>
                  <div class="form-group" (click)="checkin.show();">
                    <input type="text" placeholder="Check-in date" class="cs-input" (focus)="checkin.show();" formControlName="Checkindate" maxlength="50"
                           [ngClass]="{ 'is-invalid': SpecialRequestForms.submitted && SpecialRequestForm.controls.Checkindate.errors }" [minDate]="minDate" [maxDate]="maxDate"
                           #checkin="bsDatepicker" [outsideClick]="true" bsDatepicker readonly container
                           [bsConfig]="{dateInputFormat: CompanySettings.DateFormat,containerClass: 'theme-red',adaptivePosition: true,isAnimated: true,showWeekNumbers: false}">

                    <div *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.Checkindate.errors">
                      <div class="val-red" *ngIf="SpecialRequestForms.submitted && SpecialRequestForm.controls.Checkindate.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter check-in date </div>
                    </div>

                  </div>


                  <div class="am-pm-section" [ngStyle]="{'display': SpecialRequestForm.get('Type')?.value== '9' || SpecialRequestForm.get('Type')?.value== '217' ? '':'none'}">
                    <div class="am-part">
                      <div class="form-check itemm">
                        <input class="form-check-input" type="checkbox" formControlName="Checkintime" (change)="TimeChange()" value="" id="am" checked>
                        <label class="form-check-label" for="am"> Expected check-in time</label>
                      </div>
                      <div class="zone-select">

                        <select class="cs-select" formControlName="CheckInTimeValue">
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                        </select>

                      </div>
                      <div class="zone-select">
                        <select class="cs-select" formControlName="CheckInTimeType">
                          <option>AM</option>
                          <option>PM</option>
                        </select>
                      </div>
                    </div>
                    <div class="am-part">
                      <div class="form-check itemm">
                        <input class="form-check-input" type="checkbox" value="" id="pm" formControlName="Checkouttime" (change)="TimeChange()">
                        <label class="form-check-label" for="pm"> Expected check-out time</label>

                      </div>
                      <div class="zone-select">
                        <select class="cs-select" formControlName="CheckOutTimeValue">
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                          <option>11</option>
                          <option>12</option>
                        </select>
                      </div>
                      <div class="zone-select">
                        <select class="cs-select" formControlName="CheckOutTimeType">
                          <option>AM</option>
                          <option>PM</option>
                        </select>
                      </div>
                    </div>
                    <div *ngIf="SpecialRequestForms.submitted && (SpecialRequestForm.controls.Checkouttime.errors && SpecialRequestForm.controls.Checkintime.errors)">
                      <div class="val-red" *ngIf="SpecialRequestForms.submitted && (SpecialRequestForm.controls.Checkouttime.errors.required && SpecialRequestForm.controls.Checkintime.errors.required)"> <img src="assets/images/error-valid-img.svg" /> Please select either check-in time or check-out time </div>
                    </div>
                  </div>

                  <div class="text-area-bx">
                    <textarea placeholder="Additional Comments" class="cs-text-area" formControlName="AdditionalComments" maxlength="1000"></textarea>
                  </div>
                  <div class="cs-submit-main">
                    <div class="trigger-cs" [ngStyle]="{'display': SpecialRequestForm.get('Type')?.value== '10'
                                                                || SpecialRequestForm.get('Type')?.value== '11'
                                                                || SpecialRequestForm.get('Type')?.value== '12'
                                                                || SpecialRequestForm.get('Type')?.value== '13'
                                                                || SpecialRequestForm.get('Type')?.value== '218'
                                                                || SpecialRequestForm.get('Type')?.value== '219'
                                                                || SpecialRequestForm.get('Type')?.value== '220'
                                                                || SpecialRequestForm.get('Type')?.value== '221' ? '':'none'}">
                      <div class="switch">
                        <p>OK to pay for upgrade</p>
                        <input id="cmn-toggle-4" class="cmn-toggle cmn-toggle-round-flat" type="checkbox" formControlName="Upgrade">
                        <label for="cmn-toggle-4"></label>
                      </div>
                    </div>
                    <div class="sub-right">
                      <input type="submit" value="Submit" class="sub-cs">
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="acc-list2" *ngIf="Cancellation.length>0">
          <div class="all-atitle2" [ngClass]="{'active':TabValue == '3'}" (click)="TabValue = TabValue && TabValue == '3' ? '' : '3' "> <img src="assets/images/cancellation-cs.svg" alt="Cancellation"> Cancellation </div>
          <div class="acc-cont2" [ngClass]="{'show':TabValue == '3'}">
            <form [formGroup]="CancellationForm" (ngSubmit)="CancellationFormonSubmit()" #CancellationForms="ngForm">
              <div class="cs-form-wrap">
                <div class="cs-para">
                  <p>For all amendments please fill the below section</p>
                  <p>Ensure to provide More rooms Reference No (example: {{refText}}) to complete the request.</p>
                </div>
                <div class="cs-formwrap">
                  <div class="form-group">
                    <select class="cs-select" formControlName="Type">
                      <option *ngFor="let cat of Cancellation" value="{{cat.categoryId}}">{{cat.subcategory}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Name of lead guest" class="cs-input" formControlName="Leadguest" maxlength="50"
                           [ngClass]="{ 'is-invalid': CancellationForms.submitted && CancellationForm.controls.Leadguest.errors }">
                    <div *ngIf="CancellationForms.submitted && CancellationForm.controls.Leadguest.errors">
                      <div class="val-red" *ngIf="CancellationForms.submitted && CancellationForm.controls.Leadguest.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter lead guest name  </div>
                      <div class="val-red" *ngIf="CancellationForms.submitted && CancellationForm.controls.Leadguest.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid lead guest name  </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Booking Reference Number" formControlName="ReferenceNumber" maxlength="15" class="cs-input text-transform-uppercase"
                           [ngClass]="{ 'is-invalid': CancellationForms.submitted && CancellationForm.controls.ReferenceNumber.errors }">
                    <div *ngIf="CancellationForms.submitted && CancellationForm.controls.ReferenceNumber.errors">
                      <div class="val-red" *ngIf="CancellationForms.submitted && CancellationForm.controls.ReferenceNumber.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter reference number </div>
                      <div class="val-red" *ngIf="CancellationForms.submitted && CancellationForm.controls.ReferenceNumber.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid reference number </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Email ID" class="cs-input" formControlName="EmailID" maxlength="50"
                           [ngClass]="{ 'is-invalid': CancellationForms.submitted && CancellationForm.controls.EmailID.errors }">
                    <div *ngIf="CancellationForms.submitted && CancellationForm.controls.EmailID.errors">
                      <div class="val-red" *ngIf="CancellationForms.submitted && CancellationForm.controls.EmailID.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter email Id </div>
                      <div class="val-red" *ngIf="CancellationForms.submitted && CancellationForm.controls.EmailID.errors.pattern">  <img src="assets/images/error-valid-img.svg" /> Please enter valid email Id </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <ngx-intl-tel-input class="cs-input" [preferredCountries]="PreferredCountriesName" [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true" [customPlaceholder]="'Contact Number'" [searchCountryFlag]="true" autocomplete="disabled" [selectFirstCountry]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectedCountryISO]="CountryISOName" maxLength="11" [phoneValidation]="true"
                                        [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.National" name="ContactNumber"
                                        formControlName="ContactNumber"
                                        [ngClass]="{'is-invalid':CancellationForms.submitted && CancellationForm.controls.ContactNumber.errors}">

                    </ngx-intl-tel-input>
                    <div *ngIf="CancellationForms.submitted && CancellationForm.controls.ContactNumber.errors">
                      <div class="val-red" *ngIf="CancellationForms.submitted && CancellationForm.controls.ContactNumber.errors.required"> <img src="assets/images/error-valid-img.svg" />  Please enter contact number </div>
                      <div class="val-red" *ngIf="CancellationForms.submitted && CancellationForm.controls.ContactNumber.errors.incorrect">  <img src="assets/images/error-valid-img.svg" />  Please enter valid contact number </div>

                    </div>
                  </div>
                  <div class="form-group" (click)="checkin.show();">
                    <input type="text" placeholder="Check-in date" class="cs-input" (focus)="checkin.show();" formControlName="Checkindate" maxlength="50"
                           [ngClass]="{ 'is-invalid': CancellationForms.submitted && CancellationForm.controls.Checkindate.errors }" [minDate]="minDate" [maxDate]="maxDate"
                           #checkin="bsDatepicker" [outsideClick]="true" bsDatepicker readonly container
                           [bsConfig]="{dateInputFormat: CompanySettings.DateFormat,containerClass: 'theme-red',adaptivePosition: true,isAnimated: true,showWeekNumbers: false}">
                    <div *ngIf="CancellationForms.submitted && CancellationForm.controls.Checkindate.errors">
                      <div class="val-red" *ngIf="CancellationForms.submitted && CancellationForm.controls.Checkindate.errors.required"> Please enter check-in date</div>
                    </div>
                  </div>
                  <div class="text-area-bx">
                    <textarea placeholder="Additional Comments" class="cs-text-area" formControlName="AdditionalComments" maxlength="1000"></textarea>
                  </div>
                  <div class="cs-submit-main">
                    <div class="sub-right">
                      <input type="submit" value="Submit" class="sub-cs">
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="acc-list2" *ngIf="Refund.length>0">
          <div class="all-atitle2" [ngClass]="{'active':TabValue == '4'}" (click)="TabValue = TabValue && TabValue == '4' ? '' : '4' "> <img src="assets/images/refund-cs.svg" alt="Refund"> Refund </div>
          <div class="acc-cont2" [ngClass]="{'show':TabValue == '4'}">
            <form [formGroup]="RefundForm" (ngSubmit)="RefundFormonSubmit()" #RefundForms="ngForm">
              <div class="cs-form-wrap">
                <div class="cs-para">
                  <p>For all amendments please fill the below section</p>
                  <p>Ensure to provide More rooms Reference No (example: {{refText}}) to complete the request.</p>
                </div>
                <div class="cs-formwrap">
                  <div class="form-group">
                    <select class="cs-select" formControlName="Type">
                      <option *ngFor="let cat of Refund" value="{{cat.categoryId}}">{{cat.subcategory}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Name of lead guest" class="cs-input" formControlName="Leadguest" maxlength="50"
                           [ngClass]="{ 'is-invalid': RefundForms.submitted && RefundForm.controls.Leadguest.errors }">
                    <div *ngIf="RefundForms.submitted && RefundForm.controls.Leadguest.errors">
                      <div class="val-red" *ngIf="RefundForms.submitted && RefundForm.controls.Leadguest.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter lead guest name </div>
                      <div class="val-red" *ngIf="RefundForms.submitted && RefundForm.controls.Leadguest.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid lead guest name </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Booking Reference Number" formControlName="ReferenceNumber"  maxlength="15" class="cs-input text-transform-uppercase"
                           [ngClass]="{ 'is-invalid': RefundForms.submitted && RefundForm.controls.ReferenceNumber.errors }">
                    <div *ngIf="RefundForms.submitted && RefundForm.controls.ReferenceNumber.errors">
                      <div class="val-red" *ngIf="RefundForms.submitted && RefundForm.controls.ReferenceNumber.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter reference number </div>
                      <div class="val-red" *ngIf="RefundForms.submitted && RefundForm.controls.ReferenceNumber.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid reference number </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Email ID" class="cs-input" formControlName="EmailID" maxlength="50"
                           [ngClass]="{ 'is-invalid': RefundForms.submitted && RefundForm.controls.EmailID.errors }">
                    <div *ngIf="RefundForms.submitted && RefundForm.controls.EmailID.errors">
                      <div class="val-red" *ngIf="RefundForms.submitted && RefundForm.controls.EmailID.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter email Id </div>
                      <div class="val-red" *ngIf="RefundForms.submitted && RefundForm.controls.EmailID.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid email Id </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <ngx-intl-tel-input class="cs-input" [preferredCountries]="PreferredCountriesName" [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true" [customPlaceholder]="'Contact Number'" [searchCountryFlag]="true" autocomplete="disabled" [selectFirstCountry]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectedCountryISO]="CountryISOName" maxLength="11" [phoneValidation]="true"
                                        [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.National" name="ContactNumber"
                                        formControlName="ContactNumber"
                                        [ngClass]="{'is-invalid':RefundForms.submitted && RefundForm.controls.ContactNumber.errors}">

                    </ngx-intl-tel-input>
                    <div *ngIf="RefundForms.submitted && RefundForm.controls.ContactNumber.errors">
                      <div class="val-red" *ngIf="RefundForms.submitted && RefundForm.controls.ContactNumber.errors.required"> <img src="assets/images/error-valid-img.svg" />  Please enter contact number </div>
                      <div class="val-red" *ngIf="RefundForms.submitted && RefundForm.controls.ContactNumber.errors.incorrect">  <img src="assets/images/error-valid-img.svg" />  Please enter valid contact number </div>

                    </div>

                  </div>
                  <div class="text-area-bx">
                    <textarea placeholder="Additional Comments" class="cs-text-area" formControlName="AdditionalComments" maxlength="1000"></textarea>
                  </div>
                  <div class="cs-submit-main">
                    <div class="sub-right">
                      <input type="submit" value="Submit" class="sub-cs">
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="acc-list2" *ngIf="GeneralEnquiry.length>0">
          <div class="all-atitle2" [ngClass]="{'active':TabValue == '5'}" (click)="TabValue = TabValue && TabValue == '5' ? '' : '5' "> <img src="assets/images/general-enq-cs.svg" alt="General Enquiry"> General Enquiry </div>
          <div class="acc-cont2" [ngClass]="{'show':TabValue == '5'}">
            <form [formGroup]="GeneralEnquiryForm" (ngSubmit)="GeneralEnquiryFormonSubmit()" #GeneralEnquiryForms="ngForm">
              <div class="cs-form-wrap">
                <div class="cs-para">
                  <p>For all amendments please fill the below section</p>
                  <p>Ensure to provide More rooms Reference No (example: {{refText}}) to complete the request.</p>
                </div>
                <div class="cs-formwrap">
                  <div class="form-group">
                    <select class="cs-select" formControlName="Type" (change)="GeneralEnquiryTypeChange()">
                      <option *ngFor="let cat of GeneralEnquiry" value="{{cat.categoryId}}">{{cat.subcategory}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Name of lead guest" class="cs-input" formControlName="Leadguest" maxlength="50"
                           [ngClass]="{ 'is-invalid': GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.Leadguest.errors }">
                    <div *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.Leadguest.errors">
                      <div class="val-red" *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.Leadguest.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter lead guest name </div>
                      <div class="val-red" *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.Leadguest.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid lead guest name </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Booking Reference Number" formControlName="ReferenceNumber" maxlength="15" class="cs-input text-transform-uppercase"
                           [ngClass]="{ 'is-invalid': GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.ReferenceNumber.errors }">
                    <div *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.ReferenceNumber.errors">
                      <div class="val-red" *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.ReferenceNumber.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter reference number </div>
                      <div class="val-red" *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.ReferenceNumber.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid reference number </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Email ID" class="cs-input" formControlName="EmailID" maxlength="50"
                           [ngClass]="{ 'is-invalid': GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.EmailID.errors }">
                    <div *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.EmailID.errors">
                      <div class="val-red" *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.EmailID.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter email Id </div>
                      <div class="val-red" *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.EmailID.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid email Id  </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <ngx-intl-tel-input class="cs-input" [preferredCountries]="PreferredCountriesName" [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true" [customPlaceholder]="'Contact Number'" [searchCountryFlag]="true" autocomplete="disabled" [selectFirstCountry]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectedCountryISO]="CountryISOName" maxLength="11" [phoneValidation]="true"
                                        [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.National" name="ContactNumber"
                                        formControlName="ContactNumber"
                                        [ngClass]="{'is-invalid':GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.ContactNumber.errors}">

                    </ngx-intl-tel-input>
                    <div *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.ContactNumber.errors">
                      <div class="val-red" *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.ContactNumber.errors.required"> <img src="assets/images/error-valid-img.svg" />  Please enter contact number </div>
                      <div class="val-red" *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.ContactNumber.errors.incorrect">  <img src="assets/images/error-valid-img.svg" />  Please enter valid contact number </div>

                    </div>

                  </div>
                  <div class="form-group" (click)="checkin.show();">
                    <input type="text" placeholder={{GeneralEnquiryDate}} class="cs-input" (focus)="checkin.show();" formControlName="Checkindate" maxlength="50" [minDate]="minDate" [maxDate]="maxDate"
                           [ngClass]="{ 'is-invalid': GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.Checkindate.errors }"
                           #checkin="bsDatepicker" [outsideClick]="true" bsDatepicker readonly container
                           [bsConfig]="{dateInputFormat: CompanySettings.DateFormat,containerClass: 'theme-red',adaptivePosition: true,isAnimated: true,showWeekNumbers: false}">
                    <div *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.Checkindate.errors">
                      <div class="val-red" *ngIf="GeneralEnquiryForms.submitted && GeneralEnquiryForm.controls.Checkindate.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter check-in date  </div>
                    </div>
                  </div>
                  <div class="form-group" [ngStyle]="{'display': GeneralEnquiryForm.get('Type')?.value== '27'? '':'none'}">
                    <input type="text" placeholder="Amenity name/type" class="cs-input" formControlName="AmenityNameType" maxlength="50">
                  
                  </div>
                  <div class="text-area-bx">
                    <textarea placeholder="Additional Comments" class="cs-text-area" formControlName="AdditionalComments" maxlength="1000"></textarea>
                  </div>
                  <div class="cs-submit-main">
                    <div class="sub-right">
                      <input type="submit" value="Submit" class="sub-cs">
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="acc-list2" *ngIf="FeedbackandComplaints.length>0">
          <div class="all-atitle2" [ngClass]="{'active':TabValue == '6'}" (click)="TabValue = TabValue && TabValue == '6' ? '' : '6' "> <img src="assets/images/feedback-cs.svg" alt="Feedback and Complaints">Feedback </div>
          <div class="acc-cont2" [ngClass]="{'show':TabValue == '6'}">
            <form [formGroup]="FeedbackandComplaintsForm" (ngSubmit)="FeedbackandComplaintsFormonSubmit()" #FeedbackandComplaintsForms="ngForm">
              <div class="cs-form-wrap">
                <div class="cs-para">
                  <p>For all amendments please fill the below section</p>
                  <p>Ensure to provide More rooms Reference No (example: {{refText}}) to complete the request.</p>
                </div>
                <div class="cs-formwrap">
                  <div class="form-group">
                    <input type="text" placeholder="Name of lead guest" class="cs-input" formControlName="Leadguest" maxlength="50"
                           [ngClass]="{ 'is-invalid': FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.Leadguest.errors }">
                    <div *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.Leadguest.errors">
                      <div class="val-red" *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.Leadguest.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter lead guest name </div>
                      <div class="val-red" *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.Leadguest.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid lead guest name  </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Booking Reference Number" formControlName="ReferenceNumber" maxlength="15" class="cs-input text-transform-uppercase"
                           [ngClass]="{ 'is-invalid': FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.ReferenceNumber.errors }">
                    <div *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.ReferenceNumber.errors">
                      <div class="val-red" *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.ReferenceNumber.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter reference number </div>
                      <div class="val-red" *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.ReferenceNumber.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid reference number </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input type="text" placeholder="Email ID" class="cs-input" formControlName="EmailID" maxlength="50"
                           [ngClass]="{ 'is-invalid': FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.EmailID.errors }">
                    <div *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.EmailID.errors">
                      <div class="val-red" *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.EmailID.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter email Id </div>
                      <div class="val-red" *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.EmailID.errors.pattern"> <img src="assets/images/error-valid-img.svg" /> Please enter valid email Id </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <ngx-intl-tel-input class="cs-input" [preferredCountries]="PreferredCountriesName" [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true" [customPlaceholder]="'Contact Number'" [searchCountryFlag]="true" autocomplete="disabled" [selectFirstCountry]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectedCountryISO]="CountryISOName" maxLength="11" [phoneValidation]="true"
                                        [separateDialCode]="true" [numberFormat]="PhoneNumberFormat.National" name="ContactNumber"
                                        formControlName="ContactNumber"
                                        [ngClass]="{'is-invalid':FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.ContactNumber.errors}">

                    </ngx-intl-tel-input>
                    <div *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.ContactNumber.errors">
                      <div class="val-red" *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.ContactNumber.errors.required"> <img src="assets/images/error-valid-img.svg" />  Please enter contact number </div>
                      <div class="val-red" *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.ContactNumber.errors.incorrect">  <img src="assets/images/error-valid-img.svg" />  Please enter valid contact number </div>

                    </div>
                  </div>
                  <div class="form-group" (click)="checkin.show();">
                    <input type="text" placeholder="Check-in date" class="cs-input" (focus)="checkin.show();" formControlName="Checkindate" maxlength="50" [minDate]="minDate" [maxDate]="maxDate"
                           [ngClass]="{ 'is-invalid': FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.Checkindate.errors }"
                           #checkin="bsDatepicker" [outsideClick]="true" bsDatepicker readonly container
                           [bsConfig]="{dateInputFormat: CompanySettings.DateFormat,containerClass: 'theme-red',adaptivePosition: true,isAnimated: true,showWeekNumbers: false}">
                    <div *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.Checkindate.errors">
                      <div class="val-red" *ngIf="FeedbackandComplaintsForms.submitted && FeedbackandComplaintsForm.controls.Checkindate.errors.required"> <img src="assets/images/error-valid-img.svg" /> Please enter check-in date </div>
                    </div>
                  </div>
                  <div class="form-group" [ngStyle]="{'display': FeedbackandComplaintsForm.get('Type')?.value== '33' || FeedbackandComplaintsForm.get('Type')?.value== '233' ? '':'none'}">
                    <input type="text" placeholder="Amenity/Inclusion name" class="cs-input" formControlName="AmenityInclusionName" maxlength="50">
                   
                  </div>
                  <div class="text-area-bx">
                    <textarea placeholder="Additional Comments" class="cs-text-area" formControlName="AdditionalComments" maxlength="1000"></textarea>
                  </div>
                  <div class="cs-submit-main">
                    <div class="sub-right">
                      <input type="submit" value="Submit" class="sub-cs">
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="thank-pop" [hidden]="!Ispopup">
  <div class="error-four-main-2">
    <div class="close-change-hotel" (click)="RaiseAnotherTicket();"><img src="assets/images/close-pchange.png" alt="close"></div>
    <div class="four-img"><img alt="" src="assets/images/thank-thumb.svg"></div>
    <div class="ref-than">Reference No. <span>{{ReferenceNo}}</span> </div>
    <div class="four-page-not">Thank You! Your request has been successfully submitted</div>
    <div class="nf-details">
      We are sending you a email regarding this & working on your request,
      We will contact you soon.
    </div>

    <div class="src-again">
      <a routerLink="/">Go to Home Page</a>
      <a (click)="RaiseAnotherTicket();">Raise Another Ticket</a>
    </div>
  </div>
</div>
