import { Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { CompanySettingsService } from '../services/company-settings.service';
import { NavigationService } from '../services/navigation.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent {

  IsHomeSearchBarMobile: boolean = false;
  callText: string = '';
  callTel: string = ''; 
  languageCode: string = '';
  countryCode: string = '';
  countryCodes: any[] = ['us', 'gb'];
  dynamicUrl: string = '';
  BrandId: number = 0;
  IsMOSFilter: boolean = false;
  IsOpenCurrencyPopup: boolean = false;
  CurrencySelectedValue: string = "United States";
  CurrencyValue:string = "USD $";
  CompanySettings = CompanySettingsService;
  constructor(private _navigationService: NavigationService, private _companySetting: CompanySettingsService, private commonService: CommonService) {
    this._navigationService.HomeSearchBarMobile.subscribe(data => {
      this.IsHomeSearchBarMobile = data;
    });
  
    this.IsMOSFilter = this.commonService.isFromMOSSellingProject();
    this.dynamicUrl = window.location.pathname.split('/')[1];
    this.languageCode = this.dynamicUrl.split('-')[0];
    this.countryCode = this.dynamicUrl.split('-')[1];
    if (this.languageCode == 'en' && this.countryCodes.includes(this.countryCode)) {
      if (this.countryCode == 'gb') {
        this.BrandId = 16;
        this.callText = "0207 612 0500";
        this.callTel = "tel:02076120500";
      }
      else {
        this.BrandId = 17;       
        this.callText = "1866 389 9767";
        this.callTel = "tel:18663899767";
      }
    }
    this._companySetting.GetConfiguration(this.languageCode, this.countryCode).subscribe(responseItems => {
      var configurationResponse = responseItems as any;
      if (configurationResponse.languageCode == 'en' && this.countryCodes.includes(configurationResponse.countryCode)) {
        if (configurationResponse.countryCode == 'gb') {
          this.BrandId = 16;
          this.callText = "0207 612 0500";
          this.callTel = "tel:02076120500";
          this.CurrencySelectedValue = "United Kingdom";
        }
        else {
          this.BrandId = 17;
          this.callText = "1866 389 9767";
          this.callTel = "tel:18663899767";
          this.CurrencySelectedValue = "United States";
        }

      }
      this.CompanySettings.Brand_ID = this.BrandId;
      this.CompanySettings.CurrencySymbol = configurationResponse.currencySymbol;
      this.CompanySettings.Currency = configurationResponse.currency;
      this.CompanySettings.CultureCode = configurationResponse.cultureCode;
      this.CompanySettings.MetaChannel = configurationResponse.metaChannel;
      this.languageCode = configurationResponse.languageCode;
      this.countryCode = configurationResponse.countryCode;
    });
  }
  ChangeCurrency(event: any)
  {
    if (event.target.value == "United States") {
      this.CurrencyValue = "USD $";
      this.CurrencySelectedValue = "United States";
    }
    else {
      this.CurrencyValue = "GBP £";
      this.CurrencySelectedValue = "United Kingdom";
    }
  }
  SelectedRegion(event: any) {
    if (event == this.CurrencySelectedValue)
        return true;
      else return false;
  }
  CurrencySave() {
    let url = "";
    this.IsOpenCurrencyPopup = false;
    if (this.CurrencySelectedValue == "United States") {
      if (this.countryCode != 'us')
        url = environment.BaseURL + "en-us/hotel";
    }
    else {
      if (this.countryCode != 'gb')
      url = environment.BaseURL + "en-gb/hotel";
    }
    window.open(url, "_self");
  }
}
